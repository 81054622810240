<template>
    <div
      class="py-3 mt-4"
      style="background-color: #d3cfcf"
    >
        <div class="container px-4">
        <div class="row">
            <div class="col-md-8">
            <div class="" style="font-size:14px;">
                <p class="mb-0"><small>Copyrights © 2023 All Rights Reserved.</small></p>
                <a class="d-inline-block" href="https://www.fantini.it/privaci_policy.php" target="_blank"><small>Privacy policy</small></a>
                <a class="d-inline-block mx-2"  href="https://www.fantini.it/condizioni_generali.php" target="_blank"><small>Cookies policy</small></a>
                <a class="d-inline-block mx-2" href="https://www.fantini.it/note_legali.php" target="_blank"><small>Note Legali</small></a>
                <a class="d-inline-block" href="https://www.fantini.it/credits.php" target="_blank"><small>Credits</small></a>
            </div>
            </div>
            <div class="col-md-4 d-flex align-items-center  mt-3 mt-md-0">
                <img :src="logo_nero" class="img-fluid mx-auto" style="max-width: 95px" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
            <div class="">
                <hr>
                <p class="mb-0" style="font-size:9px;"><small>Fratelli Fantini Spa - via M. Buonarroti, 4 - 28010 Pella (NO) - c.f. / p.iva 00582310033 - fantini@legalmail.it - REA NO 134635 - Capitale sociale i.v. 2.000.000,00.</small></p>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import logo_nero from "./../assets/logoFantini-nero.png";

export default {
    data(){
        return{
            logo_nero: logo_nero
        }
    }
}
</script>