<template>
  <div class="container-fluid px-0">
    <Header></Header>
    <div class="new-visit container bg-light p-4 mt-4 rounded-3 pt-5 pb-5">
      <h1 class="text-center h2 mb-5">
        Grazie di aver compilato la richiesta di visita presso Casa Fantini
      </h1>
      <hr>
      <h1 class="text-center h2 mt-5">
        Thank you for filling out the request to visit Casa Fantini
      </h1>
    </div>
    <Footer class="position-absolute bottom-0 w-100"></Footer>
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";

export default {
  components: {
    Header,
    Footer,
  },
}
</script>

<style scoped>

</style>