<template>
    <div class="header w-100 flex-column flex-md-row position-relative d-flex align-items-center" >
      <div
        class="header-logo-wrapper w-100 row d-flex align-items-center justify-content-center"
        style="background-color: transparent"
      >
        <img :src="logo" class="img-fluid" style="max-width: 195px" />
      </div>

      <div class="header-icon-wrapper d-flex container mx-auto align-items-center" style="left:0;right:0;">
          <a href="https://fantinifratelli-my.sharepoint.com/:x:/g/personal/federico_ronco_fantini_it/EczSXRtBAgpCqvWN7VL5I-cBgZ7xdc23mT-suiKx9Dt69Q?e=4%3AMH61wE&fromShare=true&at=9" 
          target="_blank"
          v-if="showAction" class="btn d-flex flex-column align-items-center outline-focus-0 shadow-none">
            <img :src="ic_calendar" style="width: 40px;">
            <span class="fw-bold">Calendario visite Italia</span>
          </a>
      </div>
    </div>
</template>

<script>
import logo from "./../assets/Logo Fantini rosso.png";
import ic_calendar from "./../assets/icons/calendar.png";

export default {
    props: [
      'showAction'
    ],

    data(){
        return{
            logo: logo,
            ic_calendar: ic_calendar
        }
    }
}
</script>